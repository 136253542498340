import { Box, Typography } from "@mui/material";
import TableHeader, { Status } from "../../components/Table/TableHeader";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AnchorGap from "../../components/AnchorGap";
import { memo } from "react";
import useTenant from "../../hooks/useService";
import { BGPConnection, BGPSession, useApi } from "@blindspot/bspot-api-lib";
import { useRecoilValue } from "recoil";
import useReportError from "../../hooks/useReportError";
import { apiClient } from "../../state/atoms";
import getNearestTimeUnit from "../../utils/getNearestTimeUnit";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { GridColDef } from "@mui/x-data-grid";
import BlindspotTable from "../../components/BlindspotTable";

const countAndParseStatuses = (connections: BGPSession[], t: TFunction<"index", "connections">): Status[] => {
  const acceptedCount = connections.filter(connection =>
    connection.session_status.status.includes("Established")
  ).length;
  const deniedCount = connections.length - acceptedCount;

  const accepted = `${acceptedCount} ${t("count_alive")}`;
  const denied = `${deniedCount} ${t("count_down", { count: deniedCount })}`;

  return [
    { name: accepted, state: "ok", count: acceptedCount },
    { name: denied, state: "err", count: deniedCount },
  ];
};

function Connections() {
  const tenant = useTenant();
  const { t } = useTranslation("index", { keyPrefix: "connections" });
  const api = useRecoilValue(apiClient);
  const [connections, error] = useApi([api.get_bgp_sessions(), tenant]);
  useReportError(error, "get_bgp_connections");

  return (
    <>
      <AnchorGap idTarget="connections" height={30} />
      <Box sx={{ marginTop: "5.8rem" }}>
        <TableHeader
          tittle={t("title")}
          description={t("description")}
          statuses={(connections?.bgp_connections && countAndParseStatuses(connections.bgp_connections, t)) || []}
          /*buttonTittle={"Connection log"}
          buttonUrl={"connection-log"}*/
        />
        <ConnectionTable connections={connections?.bgp_connections} error={error !== undefined} />
      </Box>
    </>
  );
}

function ConnectionTable({ connections, error }: { connections: BGPSession[] | undefined; error: boolean }) {
  const { t } = useTranslation("index", { keyPrefix: "connections" });

  const columns: GridColDef[] = [
    {
      headerName: t("body.connection_id"),
      field: "connection_id",
      flex: 1,
    },
    {
      headerName: t("body.interconnection_status"),
      field: "bgpsession_status",
      flex: 1,
      sortable: false,
      filterable: false,
      valueGetter: ({ row }) => row.session_status.last_down_timestamp,
      renderCell: ({ row }) => (
        <Box display={"flex"}>
          {row.session_status.status.includes("Established") && (
            <CheckCircleIcon color="success" sx={{ width: "20px", height: "20px", top: "2px", left: "2px" }} />
          )}
          {row.session_status.status.includes("Not") && (
            <ErrorIcon color="error" sx={{ width: "20px", height: "20px", top: "2px", left: "2px" }} />
          )}
          <Box pl={1}>
            <Typography variant="subtitle2">
              {row.session_status.status.includes("Established") ? t("up") : t("link_down")}
              <br />
            </Typography>
            Since{" "}
            {getNearestTimeUnit(Date.now() - new Date(row.session_status.last_down_timestamp).getTime(), i18n.language)}
            <br />
            {row.session_status.status.includes("Established") ? t("bfd_enabled") : t("bfd_disabled")}
          </Box>
        </Box>
      ),
    },
    {
      headerName: t("body.connection_details"),
      field: "blindspot_ip",
      flex: 1,
      cellClassName: "wrap",
      renderCell: ({ row }) => (
        <>
          {t("blindspot_ip") + row.blindspot_ip}
          <br />
          {t("customer_ip") + row.customer_ip}
          <br />
        </>
      ),
    },
    {
      headerName: t("body.connection_type"),
      field: "connection_type",
      flex: 1,
      valueGetter: ({ row }) => row.connection_type.join(","),
      renderCell: ({ row }: { row: BGPConnection }) => (
        <>
          {row.connection_type.map((type, i) => (
            <Box key={i}>
              {type}
              <br />
            </Box>
          ))}
        </>
      ),
    },
    {
      headerName: t("body.bgp_session_status"),
      field: "bgp_session_status",
      flex: 1,
      sortable: false,
      filterable: false,
      cellClassName: "wrap",
      valueGetter: ({ row }) => row.session_status.last_down_timestamp,
      renderCell: ({ row }) => (
        <Box display={"flex"}>
          {row.session_status.status.includes("Established") && (
            <CheckCircleIcon color="success" sx={{ width: "20px", height: "20px", top: "2px", left: "2px" }} />
          )}
          {row.session_status.status.includes("Not") && (
            <ErrorIcon color="error" sx={{ width: "20px", height: "20px", top: "2px", left: "2px" }} />
          )}
          <Box pl={1}>
            <Typography variant="subtitle2">
              {t("status", { context: row.session_status.status.toLocaleLowerCase() })}
            </Typography>
            Since{" "}
            {getNearestTimeUnit(Date.now() - new Date(row.session_status.last_down_timestamp).getTime(), i18n.language)}
            <br />
            {t("prefixes_received", { count: row.session_status.received_prefixes_count })}
            <br />
          </Box>
        </Box>
      ),
    },
  ];
  return (
    <BlindspotTable
      error={error ? t("error") : undefined}
      rowHeight={92}
      rowsPerPage={3}
      rows={connections}
      columns={columns}
      getRowId={connections => connections.connection_id}
      noDataText={t("no_connections")}
    />
  );
}

export default memo(Connections);
