import { Box, Link, Typography } from "@mui/material";

export default function PrintFooter() {
  const currentDate = new Date(Date.now()).toDateString();
  return (
    <Box
      sx={{
        marginY: "2rem",
        display: "none",
        justifyContent: "space-between",
        alignItems: "center",
        "@media print": { display: "flex" },
      }}
    >
      <Typography variant="overline">{currentDate}</Typography>
      <Link>www.tisparkle.com</Link>
    </Box>
  );
}
