import { alpha, Box, Link, Typography, useTheme } from "@mui/material";
import Logo from "../Logo";
import PhoneIcon from "@mui/icons-material/Phone";
import React from "react";

export default function PrintHeader() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginY: "2.5rem",
        flexGrow: 1,
        display: "none",
        alignItems: "center",
        justifyContent: "space-between",
        "@media print": { display: "flex" },
      }}
    >
      <Typography variant="h2">Sparkle</Typography>
      <Box sx={{ display: "flex", justifyItems: "center" }}>
        <PhoneIcon sx={{ color: alpha(theme.palette.text.secondary, 0.56) }} />
        <Typography sx={{ mx: "8px" }}>HOTLINE</Typography>
        <Link
          href="tel:+44 20 3322 4442"
          sx={{ marginLeft: "1rem", textDecoration: "none" }}
          color="inherit"
          variant="subtitle2"
        >
          +44 20 3322 4442
        </Link>
      </Box>
    </Box>
  );
}
