import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n";

import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Chart,
  Tooltip,
  Title,
  Legend,
  registerables,
} from "chart.js";
import {
  ChoroplethController,
  ProjectionScale,
  ColorScale,
  GeoFeature,
  BubbleMapChart,
  BubbleMapController,
  SizeScale,
} from "chartjs-chart-geo";
import zoomPlugin from "chartjs-plugin-zoom";
import gradient from "chartjs-plugin-gradient";
import ChartDataLabels from "chartjs-plugin-datalabels";
import annotationPlugin from "chartjs-plugin-annotation";
import { CrosshairOptions } from "chartjs-plugin-crosshair";
import * as Sentry from "@sentry/react";
import { API_BASE_URI, RELEASE, SENTRY_DSN } from "./consts";

Sentry.init({
  dsn: SENTRY_DSN,
  tunnel: "/sentun",
  enabled: !!SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  release: RELEASE,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to cpontrol for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        window.location.origin.includes("localhost") ? "" : window.location.origin,
        API_BASE_URI,
      ],
    }),
    //Sentry.browserTracingIntegration({
    //  enableInp: true,
    //}),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
    // Sentry.feedbackIntegration({
    //   autoInject: false,
    //   colorScheme: "system",
    // }),
  ],

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

declare module "chart.js" {
  interface PluginOptionsByType<TType extends ChartType> {
    crosshair?: CrosshairOptions | boolean;
  }
}

Chart.register(LinearScale, PointElement, LineElement, BarElement, CategoryScale, ArcElement, ...registerables);
Chart.register(zoomPlugin);
Chart.register(gradient);
Chart.register(annotationPlugin);

(function () {
  const beforePrint = function () {
    for (const id in Chart.instances) {
      let color = window.getComputedStyle(document.body, null).getPropertyValue("background-color");
      const chart = Chart.instances[id];

      if (color === "rgb(21, 21, 21)") color = "rgb(31, 31, 31)";
      if (color === "rgb(244, 244, 244)") color = "rgb(255, 255, 255)";

      const { ctx } = chart;
      ctx.save();
      chart.resize();
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = color;

      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    }
  };

  if (window.matchMedia) {
    const mediaQueryList = window.matchMedia("print");
    mediaQueryList.addListener(beforePrint);
  }
})();

Chart.register(
  Title,
  Tooltip,
  Legend,
  BubbleMapChart,
  SizeScale,
  BubbleMapController,
  ChartDataLabels,
  ChoroplethController,
  ProjectionScale,
  ColorScale,
  GeoFeature
);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);
